<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    
    <div class="main about-page">
      <!-- Hero Section -->
      <div class="hero-section">
        <div class="bubbles">
          <div class="bubble" v-for="n in 15" :key="n"></div>
        </div>
        
        <div class="hero-content">
          <div class="hero-logo">
            <img src="/ssr/img/tiktok_voice_logo.svg" :title="$t('about.heroLogoTitle')" :alt="$t('about.heroLogoAlt')" height="60px" width="75px" class="top_left_logo" />
          </div>
          <h1>{{ $t('about.heroTitle') }}</h1>
          <p class="hero-subtitle">{{ $t('about.heroSubtitle') }}</p>
        </div>
      </div>

      <!-- Stats Section -->
      <section class="about-section stats-section">
        <h2>{{ $t('about.statsTitle') }}</h2>
        <p class="section-subtitle">{{ $t('about.statsSubtitle') }}</p>
        <div class="stats-grid">
          <div class="stat-item">
            <h3>50K+</h3>
            <p>{{ $t('about.statsActiveUsers') }}</p>
          </div>
          <div class="stat-item">
            <h3>1M+</h3>
            <p>{{ $t('about.statsVoicesGenerated') }}</p>
          </div>
          <div class="stat-item">
            <h3>100+</h3>
            <p>{{ $t('about.statsVoiceOptions') }}</p>
          </div>
        </div>
      </section>

      <!-- Mission Section -->
      <section class="about-section mission-section">
        <div class="section-content">
          <h2>{{ $t('about.missionTitle') }}</h2>
          <p>{{ $t('about.missionDescription') }}</p>
          <div class="mission-grid">
            <div class="mission-item">
              <div class="icon">🎯</div>
              <h4>{{ $t('about.missionAccessibilityTitle') }}</h4>
              <p>{{ $t('about.missionAccessibilityDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">💡</div>
              <h4>{{ $t('about.missionInnovationTitle') }}</h4>
              <p>{{ $t('about.missionInnovationDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">🤝</div>
              <h4>{{ $t('about.missionCommunityTitle') }}</h4>
              <p>{{ $t('about.missionCommunityDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">🌍</div>
              <h4>{{ $t('about.missionGlobalReachTitle') }}</h4>
              <p>{{ $t('about.missionGlobalReachDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">🔒</div>
              <h4>{{ $t('about.missionSecurityTitle') }}</h4>
              <p>{{ $t('about.missionSecurityDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">📈</div>
              <h4>{{ $t('about.missionGrowthTitle') }}</h4>
              <p>{{ $t('about.missionGrowthDesc') }}</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Team Section -->
      <section class="about-section team-section">
        <h2>{{ $t('about.teamTitle') }}</h2>
        <p class="section-subtitle">{{ $t('about.teamSubtitle') }}</p>
        <div class="team-image-container">
          <img src="/ssr/img/team-photo.jpg" :alt="$t('about.teamPhotoAlt')" class="team-photo" />
        </div>
        <div class="team-grid">
          <!-- Add team members as needed -->
        </div>
      </section>

      <!-- Contact Section -->
      <section class="about-section contact-section">
        <h2>{{ $t('about.contactTitle') }}</h2>
        <p class="section-subtitle">{{ $t('about.contactSubtitle') }}</p>
        <div class="contact-grid">
          <div class="contact-item">
            <div class="icon">✉️</div>
            <h4>{{ $t('about.contactEmailTitle') }}</h4>
            <a :href="sendmail">{{ $t('about.contactEmailAddress') }}</a>
          </div>
          <div class="contact-item">
            <div class="icon">𝕏</div>
            <h4>{{ $t('about.contactSocialTitle') }}</h4>
            <a href="https://x.com/aurthur711335" target="_blank" rel="noopener noreferrer">{{ $t('about.contactSocialHandle') }}</a>
          </div>
        </div>
      </section>
    </div>

    <div class="footer">
      <voice-footer></voice-footer>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import VoiceHeader from '../components/VoiceHeader.vue'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooter.vue'))

export default {
  name: 'about',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  head() {
    return {
      title: this.$t('about.headTitle'),
      keywords: this.$t('about.headKeywords'),
      description: this.$t('about.headDescription'),
      link: [
        { 
          rel: 'stylesheet', 
          href: '/ssr/css/second_style.css',
          id: 'second-style',
          media: 'all'
        }
      ]
    }
  },
  computed: {
    sendmail() {
      return `mailto:support@tiktokvoice.net`
    }
  }
}
</script>

<style scoped>
@import '/ssr/css/about.css';

.container {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}

.main {
  width: 100%;
}

.team-image-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 60px;
  padding: 0 var(--container-padding);
}

.team-photo {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}
</style>
